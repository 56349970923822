import React, { useEffect } from "react"
import Layout from '../components/layout';
import { Link } from "gatsby"
import PageNotFound from "../images/pagenotfound.jpg"
import SEO from "../components/seo";

const NotFound = () => {
  const seo = {metaDesc: '404 - Page Not Found'}
  useEffect(() => {
    if (window) {
      window.location.href = '/';
    }
  }, []);
  return (
    <></>
  )
}

export default NotFound